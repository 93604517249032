<template>
  <div class="services">
    <h2 class="h2-services">{{ $t("services.title") }}</h2>
    <div class="container">
      <div
        v-for="(column, index) in servicesColumns"
        :key="column.picture"
        class="column"
        :style="{ animationDelay: `${index * 0.5 + 0.5}s` }"
      >
        <img class="image" :src="column.picture" />
        <div class="title" v-html="$t(column.title)"></div>
        <div class="text" v-html="$t(column.text)"></div>
      </div>
    </div>
    <h3 class="subtitle">{{ $t("services.subtitle") }}</h3>
    <ButtonDefault
      :button-label="$t('services.button')"
      background="#2c3e50"
      color="white"
      @click="scrollIntoView"
    ></ButtonDefault>
  </div>
</template>

<script setup lang="ts">
import { computed, defineProps } from "vue";
import ButtonDefault from "@/components/atoms/button/ButtonDefault.vue";

defineProps<{
  title: string;
  backgroundColor: string;
  id: string;
}>();

type IService = {
  title: string;
  text: string;
  picture: string;
};

const servicesColumns = computed<Array<IService>>(() => {
  const columns: Array<IService> = []; // A IService típusú objektumok tömbje

  for (let index = 0; index < 3; index++) {
    console.log({
      picture: require(`@/assets/pictures/services/services${index}.jpg`), // Dinamikus kép útvonal
      title: `services.columns.${index}.title`, // Dinamikus cím
      text: `services.columns.${index}.text`, // Dinamikus szöveg
    });
    columns.push({
      picture: require(`@/assets/pictures/services/services${index}.jpg`), // Dinamikus kép útvonal
      title: `services.columns.${index}.title`, // Dinamikus cím
      text: `services.columns.${index}.text`, // Dinamikus szöveg
    });
  }
  return columns;
});

const scrollIntoView = () => {
  const element = document.getElementById("contact");
  if (element) {
    element.scrollIntoView({ behavior: "smooth" });
  }
};
</script>

<style scoped lang="scss">
.services {
  padding: 50px 0;

  .h2-services {
    text-transform: uppercase;
  }

  .h2-services:after {
    width: 100px;
    display: block;
    height: 2px;
    background-color: #2c3e50;
    content: "";
    margin: 30px auto;
  }

  .container {
    display: flex;
    flex-wrap: wrap;
    gap: 30px;
    margin: 30px;
  }
  .column {
    flex: 1;
    min-width: 200px;
    background-color: rgb(245, 245, 245);
    border-top-right-radius: 50px;
    border-bottom-left-radius: 50px;
    box-shadow: 1px 10px 30px #979797;
    opacity: 0;
    transform: translateY(20px);
    animation: fadeIn 1s ease forwards;
    overflow: hidden;

    .image {
      width: 100%;
    }

    .title {
      padding: 20px;
      background: #303030;
      color: $color--lightyellow;
      font-size: 16pt;
      text-transform: uppercase;
    }

    .text {
      padding: 20px;
      margin: 0 auto;
      text-align: center;
      height: auto;
      white-space: pre-line;
    }
  }

  .subtitle {
    margin-top: 70px;
  }

  @keyframes fadeIn {
    to {
      opacity: 1;
      transform: translateY(0);
    }
  }
}

@media (max-width: 768px) {
  .services {
    padding: 20px;

    .h2-services {
      font-size: 13pt;
    }
    .h2-services:after,
    .subtitle {
      margin: 30px auto;
      font-size: 13pt;
    }
    .container {
      flex-direction: column;
      font-size: 13pt;
      margin: 0px;
      height: auto;
    }
    .column {
      .title {
        font-size: 12pt;
      }
      .text {
        font-size: 13pt;
      }
    }
    .button {
      margin-top: 10px;
    }
  }
}
</style>
