import { createI18n } from "vue-i18n";
import hu from "@/locales/hu";
import en from "@/locales/en";
import de from "@/locales/de";

const locales = {
  hu,
  en,
  de,
};

const i18n = createI18n({
  legacy: false,
  locale: "hu",
  fallbackLocale: "en",
  messages: locales,
});

export default i18n;
