export default {
  navBar: {
    introduction: "Bemutatkozás",
    services: "Szolgáltatásaink",
    contact: "Kapcsolat",
    tender: "Pályázat",
    webshop: "Webshop",
  },
  landing: {
    slogan: "Az élen járunk!",
    subtitle:
      "Terméktervezés, innováció, kutatás-fejlesztés, hatóanyag-kutatás, receptúrakészítés, termékengedélyeztetés, termékbejelentés, szaktanácsadás 2019 óta. \nAgilisan, hatékonyan, szakértelemmel, elhivatottsággal. \nEzért tartozunk a legjobbak közé a piacon!",
    learnMore: "Tudjon meg többet!",
  },
  introduction: {
    title: "Minőséget szakértőktől, rövid határidővel",
    subtitle:
      "Magasan képzett, több éves szakmai tapasztalattal rendelkező mérnökeinket  elhivatottság, precizitás, valamint a szakma szeretete és tisztelete jellemzi. Ez a tudás és hozzáállás együttesen teszi lehetővé, hogy cégünk az elérhető legjobb minőséget kínálja, bármilyen megrendelői igényről legyen szó. Biztosítjuk a szükséges dokumentációt és a termék elkészültét rövid határidőn belül.",
    columns: {
      0: {
        title: "Mérnökök vagyunk",
        text: "Nagy tudással rendelke­ző fejlesztőcsapatunk kuriózuma, hogy <strong>vegyész- és biomérnökök</strong> egyaránt helyet kaptak benne. Ennek hatalmas előnye, hogy tudás bázisuk egyszerre fedi le a természet­tudományok részletes ismere­tét, valamint a preparatív laborató­riumi gyakorlato­kat.<br><br>Cégünk fejlesztései­nek eredménye­it meg­ren­delő­ink az átadott minták segítségé­vel ellenőriz­hetik, melyek kézzel­fogható módon igazol­ják, hogy azok nem csak papíron állják meg a helyüket.",
      },
      1: {
        title: "Ismerjük a titkot",
        text: "<em>„Tanuld meg a játék­szabályo­kat, aztán már csak játsza­nod kell - persze mindenki­nél jobban.”<br>– Albert Einstein –</em> <br><br>A munka valakinek csak munka, mások­nak játék, nekünk <strong>szenvedély</strong>. Ezért a fent említett „játékhoz” felfedez­tünk olyan fizikai-kémiai folyamato­kat és technoló­giai eljáráso­kat, amik a világon egyedülálló­nak számíta­nak, így amíg mások jót kínálnak, addig mi a <strong>legjobbat nyújtjuk</strong>.",
      },
      2: {
        title: "Környezettudatos",
        text: "Egy kiváló termék mit sem ér, ha lerombol­juk vele környezetün­ket, ezért cégünk a gyártások során kizárólag olyan alap­anyago­kat, csomagoló­anyago­kat hasz­nál, amelyek­kel a lehető <strong>legkisebb ökológiai lábnyom­ot</strong> hagyjuk ma­gunk után, tudatosan gondol­va ezzel a jövendő nemzedékek egészségé­re és a fenn­tartha­tó jövőre.",
      },
      3: {
        title: "Innovatív",
        text: "Egyre jobban kezd kikristályosod­ni, hogy az emberiség világról alko­tott jelenle­gi ismere­tei csak a felszínt súrol­ják. A mélyebb össze­függések, például a szinergia kapcsola­tok vizsgála­ta nehézkes, hiszen nehezen mérhetők. Mérnökeink kitartó munká­val mostan­ra az élen járnak ezek feltérképezésé­ben, melyhez saját mód­szert fejlesztet­tek ki. Ennek nyomán <strong>speciális szemlélettel</strong> vizsgál­ják az adott felada­tot, és nem a szakirodalom­ban fellelhető száraz ténye­ken alapu­ló módszerek­kel dolgoz­nak.",
      },
    },
  },
  services: {
    title: "Elérhető szolgáltatásaink",
    columns: {
      0: {
        title: "Egyedi termékfejlesztés",
        text: "Az Ön által elképzelt terméket alkotjuk meg aszerint, ahogy kívánja, kiváló szakértelemmel fűszerezve, megfeleltetve azt a jogszabályi követelményeknek.\n\n Pontosan realizáljuk a felhasználói igényeket úgy, hogy az elkészült termék képes legyen felvenni a versenyt a piacon fellelhető versenytársak által kínált egyéb produktumokkal. Elképzelését támogatjuk és kivitelezzük az ötlettől a megvalósulásig!\n\nA folyamatokat gyorsan, precízen elkészített minták segítségével ellenőrizheti, és minden esetben személyre szabott árajánlatot kap.",
      },
      1: {
        title: "Kozmetikumok",
        text: "A legtöbb gyártó a kozmetikumok előállításához mesterséges illatanyagokat, színezékeket és más szintetikusan előállított segédanyagokat használ. Ezzel szemben mi kizárólag bio alapanyagokkal dolgozunk és csak a természetes összetevőket részesítjük előnyben.  \n\nJelenleg kozmetikai termékek receptúrájának fejlesztését, standard minta elkészítését és kozmetikai termékek engedélyeztetését vállaljuk, szem előtt tartva a megrendelői igényeket.",
      },
      2: {
        title: "Táplálék-kiegészítők",
        text: "Napjainkban egyre népszerűbb a tudatos egészséges életmód, melynek szerves részét képezi a megelőzés. A hangsúly a tüneti kezelésről mindinkább átkerül a prevencióra. Ez a szemléletmód az étrend-kiegészítők létjogosultsá­gá­nak folyamatosan növekvő teret ad.\n\nA legkülönfélébb formában tudjuk kifejleszteni és gyártani őket, legyen szó porkeverékről, kapszuláról, ital­porról, vagy folyékony étrend-kiegészítőről. Jelenleg is számos újszerű termék gyártását végezzük.",
      },
    },
    subtitle: "További ötleteivel, elképzeléseivel, kérdéseivel kérjük, forduljon hozzánk bizalommal!",
    button: "Lépjen kapcsolatba velünk",
  },
  contact: {
    title: "Elérhetőségeink",
    text: "Amennyiben felkeltettük érdeklődését, vagy további információra lenne szüksége szolgáltatásainkkal, termékeinkkel vagy kutatásainkkal kapcsolatban, kérjük vegye fel velünk a kapcsolatot az alábbi elérhetőségek valamelyikén. \nMegkeresését szívesen fogadjuk! Lendületes csapatunk örömmel áll rendelkezésére!",
    companyName: "Cégnév",
    email: "E-mail",
    site: "Telephely és székhely",
    phoneNumber: "Telefonszám",
    page: "oldal",
  },
  tender: {
    title: "Pályázat, irányelveink",
  },
  footer: {
    followUs: "Kövess be",
    rightsReserved: "Minden jog fenntartva",
  },
};
