<template>
  <footer class="footer">
    <div class="footer-content">
      <!-- Cég neve és elérhetőségek -->
      <div class="footer-section company-info">
        <h3>Draconic Technology Kft.</h3>
        <p>{{ $t("contact.site") }}: 8100 Várpalota, Bezerédi u. 24.</p>
        <p>
          E-mail: <a :href="`mailto:${emailAddress}`">{{ emailAddress }}</a>
        </p>
        <p>
          Telefon: <a :href="`tel:${phoneNumber}`">{{ phoneNumber }}</a>
        </p>
      </div>

      <!-- Jogi információk -->
      <div class="footer-section legal-info">
        <p>&copy; 2024 Draconic Technology Kft.</p>
        <p>{{ $t("footer.rightsReserved") }}</p>
      </div>

      <!-- Közösségi média linkek -->
      <div class="footer-section social-media">
        <h3>{{ $t("footer.followUs") }}</h3>
        <div class="social-icons">
          <p>
            <a href="https://www.facebook.com/draconictechnology" target="_blank" rel="noopener noreferrer">
              <font-awesome-icon :icon="['fab', 'facebook']" class="icon" /> draconictechnology
            </a>
          </p>
          <p>
            <a href="https://www.instagram.com/draconictechnology" target="_blank" rel="noopener noreferrer">
              <font-awesome-icon :icon="['fab', 'instagram']" class="icon" /> draconictechnology
            </a>
          </p>
        </div>
      </div>
    </div>
  </footer>
</template>

<script setup lang="ts">
const phoneNumber = "+36202188956";
const emailAddress = "draconic@draconic.hu";
</script>

<style scoped lang="scss">
.footer {
  background-color: #2c3e50;
  color: white;
  display: flex;
  justify-content: space-around;
  flex-wrap: wrap;
  align-items: center;

  .footer-content {
    display: flex;
    flex-wrap: wrap;
    justify-content: space-between;
    width: 100%;
  }

  .footer-section {
    margin: auto;
    flex: 1;
    min-width: 250px;
    max-width: 400px;
    height: 100%;

    h3 {
      font-size: 18px;
      margin-bottom: 10px;
      color: #f4e757;
    }

    p,
    a {
      font-size: 14px;
      color: white;
      text-decoration: none;
    }

    a:hover {
      text-decoration: underline;
    }

    .social-icons {
      gap: 15px;
      a {
        font-size: 15px;
        color: white;
        transition: color 0.3s;

        &:hover {
          color: #f4e757;
        }
      }
    }
  }

  .legal-info {
    text-align: center;
    flex-basis: 100%;

    p,
    a {
      font-size: 14px;
    }
  }
}
</style>
