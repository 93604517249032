<template>
  <div class="contact">
    <h2 class="h2-contact">{{ $t("contact.title") }}</h2>
    <h3 class="h3-contact">{{ $t("contact.text") }}</h3>
    <div class="info" :style="{ animationDelay: '0.5s' }">
      <table class="contact-table">
        <tbody>
          <tr>
            <th>{{ $t("contact.companyName") }}</th>
            <td>Draconic Technology Kft.</td>
          </tr>
          <tr>
            <th>{{ $t("contact.site") }}</th>
            <td>8100 Várpalota, Bezerédi u. 24.</td>
          </tr>
          <tr>
            <th>{{ $t("contact.email") }}</th>
            <td>
              <a :href="`mailto:${emailAddress}`">
                draconic@draconic.hu <font-awesome-icon icon="envelope" class="icon" />
              </a>
            </td>
          </tr>
          <tr>
            <th>{{ $t("contact.phoneNumber") }}</th>
            <td>
              <a :href="`tel:${phoneNumber}`"> +36 20 2188 956 <font-awesome-icon icon="phone" class="icon" /> </a>
            </td>
          </tr>
          <tr>
            <th>Facebook</th>
            <td>
              <a href="https://www.facebook.com/draconictechnology" target="_blank" rel="noopener noreferrer">
                <font-awesome-icon :icon="['fab', 'facebook']" class="icon" /> draconictechnology
              </a>
            </td>
          </tr>
          <tr>
            <th>Instagram</th>
            <td>
              <a href="https://www.instagram.com/draconictechnology" target="_blank" rel="noopener noreferrer">
                <font-awesome-icon :icon="['fab', 'instagram']" class="icon" /> draconictechnology
              </a>
            </td>
          </tr>
        </tbody>
      </table>
    </div>
    <!-- <img src="@/assets/pictures/logo.svg" alt="draconic logo" class="contactLogo" /> -->
  </div>
</template>

<script setup lang="ts">
import { defineProps } from "vue";

defineProps<{
  title: string;
}>();

const phoneNumber = "+36202188956";
const emailAddress = "draconic@draconic.hu";
</script>

<style scoped lang="scss">
.contact {
  padding-top: 50px;
  position: relative;

  .h2-contact {
    text-transform: uppercase;
  }

  .h2-contact:after {
    width: 100px;
    display: block;
    height: 2px;
    background-color: #2c3e50;
    content: "";
    margin: 30px auto;
  }

  .h3-contact {
    max-width: 80%;
    margin: auto;
    white-space: pre-line;
    line-height: 27px;
    font-weight: normal;
  }

  .info {
    font-size: 15pt;
    padding: 10px 10px;
    line-height: 30px;

    background-color: rgb(245, 245, 245);
    border-top-right-radius: 50px;
    border-bottom-left-radius: 50px;
    box-shadow: 1px 10px 30px #979797;
    margin: 50px auto 0 auto;
    opacity: 0;
    transform: translateY(20px);
    animation: fadeIn 1s ease forwards;
    max-width: 50vw;
  }

  .contact-table {
    width: auto;
    border-collapse: collapse;
    margin: auto;

    th,
    td {
      padding: 10px;
      text-align: left;
      white-space: nowrap;
    }

    th {
      font-weight: normal;
      color: #2c3e50;
      text-transform: uppercase;
      text-align: right;
    }

    td {
      font-weight: bold;
      color: #333;
    }

    a {
      text-decoration: none;
      color: #2c3e50;
      &:hover {
        text-decoration: underline;
      }
    }

    .icon {
      margin-left: 5px;
    }
  }

  .contactLogo {
    right: 220px;
    bottom: -370px;
    position: absolute;
    opacity: 0.4;
    color: black;
    z-index: 100;
    width: 380px;
  }
  @keyframes fadeIn {
    to {
      opacity: 1;
      transform: translateY(0);
    }
  }
}

@media (max-width: 992px) {
  .contact {
    padding: 20px;

    .h2-contact {
      font-size: 18pt;
    }
    .h2-contact:after,
    .subtitle {
      margin: 30px auto;
      font-size: 14pt;
    }
    .h3-contact {
      font-size: 13pt;
      margin: auto;
    }
    .info {
      margin: 0;
      max-width: 100%;
      font-size: 15pt;
      padding: 0;
      margin-top: 20px;
      th,
      td {
        white-space: pre-wrap;
      }
      th {
        width: 38%;
        padding: 0;
      }
    }
    .contactLogo {
      display: none;
    }
  }
}

@media (max-width: 768px) {
  .h2-contact {
    font-size: 13pt;
  }
  .h2-contact:after,
  .subtitle {
    margin: 30px auto;
    font-size: 13pt;
  }
  .h3-contact {
    font-size: 12pt;
    margin: auto;
  }
  .info {
    font-size: 14pt;
  }
}

@media (max-width: 576px) {
  .contact {
    padding: 20px;

    .info {
      font-size: 12pt;
    }
  }
}
</style>
