<template>
  <div class="nav-bar">
    <NavBar :nav-items="navItems"></NavBar>
    <LanguageSelector></LanguageSelector>
  </div>
  <div class="placeholder"></div>
  <router-view class="router-view" :nav-items="navItems" />
</template>

<script setup>
import NavBar from "@/components/organisms/NavBar/NavBar.vue";
import LanguageSelector from "@/components/molecules/LanguageSelector/LanguageSelector.vue";
import LogoImage from "@/assets/pictures/logo_white_alphabackground.png";
import { onMounted, onUnmounted, ref } from "vue";

const navItems = ref([
  {
    id: "home",
    name: "home",
    label: "",
    picture: LogoImage,
    backgroundColor: "#0477b4",
    componentName: "LandingSection",
    backgroundImage: "/assets/pictures/background.jpg",
  },
  {
    id: "introduction",
    name: "introduction",
    label: "navBar.introduction",
    backgroundColor: "#efefef",
    componentName: "IntroductionSection",
  },
  {
    id: "services",
    name: "services",
    label: "navBar.services",
    backgroundColor: "#ffdb6f", // $color-lightyellow
    componentName: "ServicesSection",
  },
  {
    id: "contact",
    name: "contact",
    label: "navBar.contact",
    backgroundColor: "white",
    componentName: "ContactSection",
  },
  {
    id: "tender",
    name: "tender",
    label: "navBar.tender",
    backgroundColor: "white",
    componentName: "TenderSection",
  },
  {
    id: "webshop",
    name: "",
    link: "http://draconicwebshop.unas.hu",
    label: "navBar.webshop",
    backgroundColor: "",
    componentName: "",
  },
  { id: "footer", name: "footer", label: "", backgroundColor: "violet", componentName: "FooterSection" },
]);

const activeSection = ref("");

const handleScroll = () => {
  // navItems.value.forEach((section) => {
  //   const sectionElement = document.getElementById(section.id);
  //   if (sectionElement) {
  //     const rect = sectionElement.getBoundingClientRect();
  //     if (rect.top >= 0 && rect.top <= window.innerHeight / 2) {
  //       activeSection.value = section.id;
  //       sectionElement.scrollIntoView({ behavior: "smooth" }); // Helyi görgetés
  //     }
  //   }
  // });
};

onMounted(() => {
  window.addEventListener("scroll", handleScroll);
  activeSection.value = "";
});

onUnmounted(() => {
  window.removeEventListener("scroll", handleScroll);
});
</script>

<style lang="scss">
#app {
  font-family: Avenir, Helvetica, Arial, sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  text-align: center;
  color: #2c3e50;
  position: relative;
}

body {
  margin: 0px;
}

.nav-bar {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  display: flex;
  background-color: $color--navbar;
  z-index: 1000;
}

nav {
  padding: 6px 10px;
  height: 65px;
  width: 100%;

  a {
    font-weight: bold;
    color: #2c3e50;

    &.router-link-exact-active {
      color: #42b983;
    }
  }
}
.placeholder {
  height: 77px;
  position: relative;
}
</style>
