<template>
  <div v-if="language" class="language-option">
    <div class="name">
      {{ language.name }}
    </div>
    <img class="flag" :src="flagUrl" />
  </div>
</template>

<script lang="ts" setup>
import { ref, defineProps, onMounted, watch } from "vue";
import { ILanguage } from "@/components/molecules/LanguageSelector/utils/ILanguage";

const props = defineProps<{
  language: ILanguage;
}>();

const flagUrl = ref<string | undefined>(undefined);

const loadFlag = async () => {
  try {
    const flagModule = await import(`@/assets/pictures/flags/${props.language.name}.png`);
    flagUrl.value = flagModule.default;
  } catch (error) {
    console.error("Hiba történt a kép betöltésekor:", error);
  }
};

onMounted(() => {
  loadFlag();
});

watch(
  () => props.language,
  () => {
    loadFlag();
  }
);
</script>

<style scoped lang="scss">
.language-option {
  display: inline-flex;

  .name {
    margin: auto 8px auto 0px;
    font-weight: 700;
    color: white;
  }

  .flag {
    height: 30px;
    border: 0.5px solid white;
  }
}
</style>

function require(arg0: string) { throw new Error("Function not implemented."); }
