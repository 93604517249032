<template>
  <div v-if="!isMobile">
    <nav style="display: flex; justify-content: flex-start">
      <template v-for="navItem in navItems" :key="navItem.label">
        <a :href="`#${navItem.id}`" v-if="navItem.picture" style="margin: auto 18px; font-size: 18px">
          <img v-if="navItem.picture" style="height: 65px" :src="navItem.picture" />
        </a>
        <a
          :href="`#${navItem.id}`"
          v-if="navItem && navItem.label && !navItem.link"
          style="margin: auto 18px; font-size: 18px; color: white; text-decoration-line: none"
        >
          {{ $t(navItem.label) }}
        </a>
        <a
          :href="`${navItem.link}`"
          v-else
          style="margin: auto 18px; font-size: 18px; color: white; text-decoration-line: none"
        >
          {{ $t(navItem.label) }}
        </a>
      </template>
    </nav>
  </div>
  <div v-else style="height: 77px; line-height: 77px; font-weight: 600">
    <a
      href="http://draconicwebshop.unas.hu"
      style="margin: auto; font-size: 18px; vertical-align: middle; color: white; text-decoration-line: none"
    >
      Webshop
    </a>
  </div>
</template>

<script lang="ts" setup>
import { defineProps, ref, onMounted } from "vue";
import { INavItem } from "./utils/INavItem";

const props = defineProps<{
  navItems?: Array<INavItem>;
}>();

// Define reactive variables
const isMobile = ref(false);

// Function to check screen size
const checkScreenSize = () => {
  isMobile.value = window.innerWidth <= 768; // Example breakpoint for mobile
};

// Set up the component
onMounted(() => {
  checkScreenSize();
  window.addEventListener("resize", checkScreenSize);
});

// Make reactive variables and functions available to the template
const navItems = props.navItems || [];
</script>
