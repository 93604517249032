export default {
  navBar: {
    introduction: "Introduction",
    services: "Our services",
    contact: "Contact",
    tender: "Tender",
    webshop: "Webshop",
  },
  landing: {
    slogan: "We are at the forefront!",
    subtitle:
      "Product design, innovation, research and development, active ingredient research, formulation development, product licensing, product registration, \nand professional consulting since 2019. \nAgile, efficient, with expertise and dedication. \nThat's why we are among the best in the market!",
    learnMore: "Learn more!",
  },
  introduction: {
    title: "Quality from experts, with a short deadline",
    subtitle:
      "Our highly qualified engineers with many years of professional experience are characterized by professionalism, precision, and love and respect for the profession. Together, this knowledge and attitude make it possible for our company to offer the best quality available, regardless of the customer's needs. We ensure the necessary documentation and the completion of the product within a short period of time.",
    columns: {
      0: {
        title: "Wir sind Ingenieure",
        text: "The curiosity of our highly knowledgeable development team is that it includes both chemical and bioengineers. The huge advantage of this is that their knowledge base covers both detailed knowledge of the natural sciences and preparative laboratory practices. Our customers can check the results of our company's developments with the help of the provided samples, which prove in a tangible way that they are not just on paper.",
      },
      1: {
        title: "We know the secret",
        text: 'Learn the rules of the game, and then you just have to play - of course better than everyone else." - Albert Einstein - Work for some is just work, for others it\'s a game, for us it\'s a passion. Therefore, for the above-mentioned "game", we discovered such physico-chemical processes and technological procedures that are considered unique in the world, so while others offer good, we offer the best.',
      },
      2: {
        title: "Environmentally conscious",
        text: "An excellent product is worthless if we destroy our environment with it, so our company only uses raw materials and packaging materials during production that leave the smallest possible ecological footprint, consciously thinking about the health of future generations and a sustainable future.",
      },
      3: {
        title: "Innovative",
        text: "It is beginning to crystallize more and more that humanity's current knowledge of the world only scratches the surface. Examining deeper relationships, such as synergy relationships, is difficult, as they are difficult to measure. With persistent work, our engineers are now at the forefront of mapping them, for which they have developed their own method As a result, they examine the given task with a special approach and do not work with methods based on dry facts found in the literature.",
      },
    },
  },
  services: {
    title: "Provided services",
    columns: {
      0: {
        title: "Unique product development",
        text: "We create the product you envisioned according to your wishes, seasoned with excellent expertise and complying with the legal requirements. . We support and implement your vision from idea to realization!\n\nYou can quickly and precisely check the processes with the help of prepared samples, and in each case you will receive a personalized price offer.",
      },
      1: {
        title: "Cosmetics",
        text: "Most manufacturers use artificial fragrances, dyes and other synthetically produced excipients for the production of cosmetics. On the other hand, we only work with organic ingredients and prefer only natural ingredients. \n\nWe currently undertake the development of cosmetic product recipes, the preparation of standard samples and the licensing of cosmetic products, keeping in mind the customer's needs.",
      },
      2: {
        title: "Food supplements",
        text: "Nowadays, a conscious healthy lifestyle is becoming more and more popular, of which prevention is an integral part. The focus is increasingly shifting from symptomatic treatment to prevention. This approach gives dietary supplements the right to exist.\n\nWe can develop and manufacture them in a wide variety of forms, be it a powder mixture, capsule, drink powder, or liquid dietary supplement. We are currently manufacturing many innovative products.",
      },
    },
    subtitle: "Please feel free to contact us with your further ideas, concepts, and questions!",
    button: "Contact us",
  },
  contact: {
    title: "Our contacts",
    text: "If you are interested, or if you need more information about our services, products or research, please contact us at one of the contact details below. We welcome your inquiry! Our energetic team is happy to help you!",
    companyName: "Company name",
    email: "E-mail",
    site: "Site and registered office",
    phoneNumber: "Telephone number",
    page: "page",
  },
  tender: {
    title: "Tender and our guidelines",
  },
  footer: {
    followUs: "Follow us",
    rightsReserved: "All rights reserved",
  },
};
