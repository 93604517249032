<template>
  <div class="section-style">
    <SzechenyiTender @click="scrollIntoView('tender')"></SzechenyiTender>
    <div class="landing-content">
      <img alt="DraconicTechnology logo" src="@/assets/pictures/logoTransparent.png" class="logo-center p-0" />
      <div class="landing-texts">
        <h1 class="h1-title">Draconic Technology</h1>
        <p class="landing-slogan">{{ $t("landing.slogan") }}</p>
        <div class="part3">
          <h2 class="h2-landing">
            {{ $t("landing.subtitle") }}
          </h2>
          <ButtonDefault
            :button-label="$t('landing.learnMore')"
            background="#f4e757"
            @click="scrollIntoView('introduction')"
            class="button"
          ></ButtonDefault>
        </div>
      </div>
    </div>
  </div>
</template>

<script lang="ts" setup>
import { defineProps } from "vue";
import ButtonDefault from "@/components/atoms/button/ButtonDefault.vue";
import SzechenyiTender from "@/components/atoms/szechenyi/SzechenyiTender.vue";

defineProps<{
  title: string;
  backgroundColor?: string;
  backgroundImage?: string;
  id: string;
}>();

const scrollIntoView = (to: string) => {
  const element = document.getElementById(to);
  if (element) {
    element.scrollIntoView({ behavior: "smooth" });
  }
};
</script>

<style scoped lang="scss">
.section-style {
  height: 100vh;
  background-repeat: no-repeat;
  background-attachment: scroll;
  background-position: center center;
  background-size: cover;
  background-image: url("~@/assets/pictures/background.jpg");
  max-width: 100%;
  position: relative;

  .landing-content {
    position: relative;
    margin: auto;
    display: flex;
    background: linear-gradient(to top, rgba(128, 128, 128, 0) 0%, rgb(4 119 180) 45%);

    .logo-center {
      filter: drop-shadow(-1px -2px 0 white) drop-shadow(2px 1px 0 white);
      transform-origin: bottom;
      background: radial-gradient($color--lightyellow -185px, transparent 34%);
      width: 30vw;
      height: auto;
      display: inline-block;
      position: absolute;
      top: 0;
      left: 0;

      opacity: 0;
      transform: translateY(20px);
      animation: fadeIn 1s ease forwards;
      animation-delay: 0s;
    }

    .landing-texts {
      display: inline-block;
      padding-left: 170px;
      padding-bottom: 170px;
      padding-top: 150px;
      width: 1000px;
      margin: auto;

      .h1-title {
        margin: 0px;
        font-family: Orbitron;
        font-weight: 900;
        font-size: 52pt;
        color: black;
        letter-spacing: 2px;
        text-shadow: 2px 0px white, 0 -1px white;

        opacity: 0;
        transform: translateY(20px);
        animation: fadeIn 1s ease forwards;
        animation-delay: 0s;
      }

      .landing-slogan {
        font-size: 34pt;
        font-family: orbitron;
        font-weight: 600;
        color: #eee378;
        text-shadow: 2px 0px black, 0 -1px black;

        opacity: 0;
        transform: translateY(20px);
        animation: fadeIn 1s ease forwards;
        animation-delay: 1s;
      }

      .part3 {
        color: $color--white;
        margin-top: 60px;
        white-space: pre-line;

        opacity: 0;
        transform: translateY(500px);
        animation: fadeIn 0.5s ease forwards;
        animation-delay: 2s;

        .h2-landing {
          font-size: 16pt;
        }
      }
    }
  }
}

@keyframes fadeIn {
  to {
    opacity: 1;
    transform: translateY(0);
  }
}

@media (max-width: 992px) {
  .section-style {
    height: 100%;
    .landing-content {
      padding: 50px 0px;
      .landing-texts {
        padding: 250px 0 0 0;

        .part3 {
          margin: 30px 0;
          .h2-landing {
            font-size: 17pt;
          }
        }
      }
    }
  }
}

@media (max-width: 769px) {
  .section-style {
    height: 100%;
    .landing-content {
      padding: 50px 0px;
      .landing-texts {
        padding: 150px 0 0 0;
        .part3 {
          margin: 30px 0;
          .h2-landing {
            font-size: 16pt;
          }
        }
      }
    }
  }
}

/* Media Queries */
@media (max-width: 576px) {
  .section-style {
    height: 100%;
    .landing-content {
      padding-top: 50px;
      .landing-texts {
        padding-left: 10px;
        padding-bottom: 10px;
        padding-top: 120px;
        width: auto;

        .h1-title {
          font-size: 22pt;
        }

        .landing-slogan {
          font-size: 15pt;
        }

        .part3 {
          margin: 30px 0;

          .h2-landing {
            font-size: 12pt;
          }
        }
      }
    }
  }
}
</style>
