<template>
  <div class="introduction" :style="{ backgroundColor }">
    <h2 class="h2-introduction">{{ $t("introduction.title") }}</h2>
    <p class="subtitle">{{ $t("introduction.subtitle") }}</p>
    <div class="container">
      <div
        v-for="(column, index) in updatedColumns"
        :key="column.icon"
        class="column"
        :style="{ animationDelay: `${index * 0.5 + 0.5}s` }"
      >
        <div class="col-content">
          <font-awesome-icon :icon="column.icon" class="icon" />
          <div class="title" v-html="$t(column.title)"></div>
          <div class="text" v-html="$t(column.text)"></div>
        </div>
      </div>
    </div>
  </div>
</template>

<script lang="ts" setup>
import { computed, defineProps } from "vue";

defineProps<{
  title: string;
  backgroundColor: string;
  id: string;
}>();

const columns = [
  {
    icon: "users",
  },
  {
    icon: "key",
  },
  {
    icon: "seedling",
  },
  {
    icon: "cogs",
  },
];

const updatedColumns = computed(() =>
  columns.map((item, index) => ({
    ...item,
    title: "introduction.columns." + index + ".title",
    text: "introduction.columns." + index + ".text",
  }))
);
</script>

<style scoped lang="scss">
.introduction {
  padding-top: 70px;

  .h2-introduction {
    text-transform: uppercase;
  }

  .h2-introduction:after {
    width: 100px;
    display: block;
    height: 2px;
    background-color: $color--lightyellow;
    content: "";
    margin: 50px auto;
  }

  .subtitle {
    width: 80vw;
    margin: 50px auto;
    font-size: 14pt;
  }

  .container {
    display: flex;
    flex-wrap: wrap;
    gap: 30px;
    margin: 30px;
  }

  .column {
    flex: 1;
    min-width: 200px;
    padding: 20px;
    background-color: rgb(245, 245, 245);
    border-top-right-radius: 50px;
    border-bottom-left-radius: 50px;
    padding: 50px 20px;
    box-shadow: 1px 10px 30px #979797;
    opacity: 0;
    transform: translateY(20px);
    animation: fadeIn 1s ease forwards;
  }

  .col-content {
    margin: auto;
  }

  @keyframes fadeIn {
    to {
      opacity: 1;
      transform: translateY(0);
    }
  }

  .icon {
    width: 30px;
    height: auto;
    transition: width 0.5s ease;
  }

  .title {
    text-transform: uppercase;
    margin: 20px 0;
    font-size: 15pt;
    transition: font-size 0.5s ease;
    font-weight: 500;
    transition: font-weight 0.5 ease;
  }

  .text {
    text-align: justify;
    line-height: 25px;
  }

  .column:hover {
    box-shadow: 1px 10px 30px #d3d3d3;
    color: black;
    background-color: #fff;

    .icon {
      width: 50px;
      transition: width 0.5s ease;
    }

    .title {
      transition: font-size 0.5s ease;
      font-size: 16pt;
      font-weight: 600;
    }

    .text {
    }
  }
}

@media (max-width: 576px) {
  .introduction {
    padding: 20px;

    .h2-introduction {
      font-size: 13pt;
    }
    .h2-introduction:after,
    .subtitle {
      margin: 30px auto;
      font-size: 13pt;
    }
    .container {
      flex-direction: column;
      font-size: 12pt;
      margin: 0px;
      height: auto;
    }
    .column {
      padding: 20px;
    }
  }
}
</style>
