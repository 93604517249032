<template>
  <img
    alt="Európai Unió Regionális Fejlesztési Alap Logo"
    src="@/assets/pictures/szechenyi/korszerusites_palyazat_logo.png"
    class="palyazat-logo-png"
  />
</template>

<script setup lang="ts"></script>

<style scoped lang="scss">
.palyazat-logo-png {
  width: 360px;
  height: auto;
  cursor: pointer;
  position: absolute;
  top: 0;
  right: 0;
  z-index: 100;

  @media (max-width: 576px) {
    width: 150px;
  }
}
</style>
