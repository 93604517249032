<template>
  <div class="language-selector">
    <LanguageOptions class="selected-language" :language="selectedLanguage"></LanguageOptions>
    <div class="dropdown" v-if="availableLanguages">
      <div
        v-for="language in availableLanguages"
        :key="language.name"
        @click="selectLanguage(language)"
        class="dropdown-items"
      >
        <LanguageOptions :language="language"></LanguageOptions>
      </div>
    </div>
  </div>
</template>

<script lang="ts" setup>
import { computed, ref } from "vue";
import { ILanguage } from "./utils/ILanguage";
import { useI18n } from "vue-i18n";

import LanguageOptions from "./components/LanguageOption/LanguageOptions.vue";

const { locale } = useI18n();

const languages = ref<ILanguage[]>([
  {
    name: "HU",
    path: "@/assets/pictures/flags/",
    selectedLanguage: true,
  },
  {
    name: "EN",
    path: "@/assets/pictures/flags/",
    selectedLanguage: false,
  },
  {
    name: "DE",
    path: "@/assets/pictures/flags/",
    selectedLanguage: false,
  },
]);

const selectLanguage = (language: { name: string; flag: string; selectedLanguage: boolean }) => {
  languages.value.forEach((item) => {
    item.selectedLanguage = false;
    if (language.name === item.name) {
      item.selectedLanguage = true;
      locale.value = item.name.toLocaleLowerCase();
    }
  });
};

const selectedLanguage = computed(() => {
  return languages.value.find((item) => item.selectedLanguage);
});
const availableLanguages = computed(() => {
  return languages.value.filter((item) => item.selectedLanguage == false);
});
</script>

<style lang="scss">
.language-selector {
  width: 130px;
  margin: auto;
  margin-right: 10px;
  background-color: $color--navbar;
}

.dropdown {
  position: absolute;
  display: grid;
  padding: 25px;
  padding-top: 0;

  .dropdown-items {
    display: inline-flex;
    margin-top: 16px;
    visibility: hidden;
  }

  &:hover,
  &:hover .dropdown-items {
    visibility: visible;
    background: $color--navbar;
  }
}

.selected-language:hover ~ .dropdown,
.selected-language:hover ~ .dropdown > .dropdown-items {
  background: $color--navbar;
  visibility: visible;
}
</style>
