export default {
  navBar: {
    introduction: "Einführung",
    services: "Unsere Leistungen",
    contact: "Kontakt",
    tender: "Anwendung",
    webshop: "Webshop",
    footer: "Footer",
  },
  landing: {
    slogan: "Wir sind vorne dabei!",
    subtitle:
      "Produktdesign, Innovation, Forschung und Entwicklung, Wirkstoffforschung, Rezepturentwicklung, Produktzulassung, Produktregistrierung \nund Fachberatung seit 2019. \nAgil, effizient, mit Fachkompetenz und Engagement. \nDeshalb gehören wir zu den Besten auf dem Markt!",
    learnMore: "Erfahren Sie mehr!",
  },
  introduction: {
    title: "Qualität vom Experten, mit kurzer Frist",
    subtitle:
      "Unsere hochqualifizierten Ingenieure mit langjähriger Berufserfahrung zeichnen sich durch Professionalität, Präzision sowie Liebe und Respekt für den Beruf aus. Zusammengenommen ermöglichen dieses Wissen und diese Einstellung es unserem Unternehmen, unabhängig von den Bedürfnissen des Kunden die beste verfügbare Qualität anzubieten. Wir sorgen für die notwendige Dokumentation und die Fertigstellung des Produkts innerhalb kurzer Zeit.",
    columns: {
      0: {
        title: "Wir sind Ingenieure",
        text: 'Das Besondere an unserem hochqualifizierten Entwicklungsteam ist, dass es sowohl Chemie- als auch Bioingenieure umfasst. Der große Vorteil dabei ist, dass ihre Wissensbasis sowohl detaillierte Kenntnisse der Naturwissenschaften als auch präparative Laborpraktiken umfasst. Unsere Kunden können die Ergebnisse überprüfen." Die Entwicklungen unseres Unternehmens anhand der bereitgestellten Muster, die greifbar beweisen, dass sie nicht nur auf dem Papier stehen.',
      },
      1: {
        title: "Wir kennen das Geheimnis",
        text: 'Lerne die Spielregeln, und dann musst du nur noch spielen – natürlich besser als alle anderen." – Albert Einstein – Für manche ist Arbeit nur Arbeit, für andere ist es ein Spiel, für uns ist es eine Leidenschaft. Deshalb haben wir für das oben genannte "Spiel" solche physikalisch-chemischen Prozesse und technologischen Verfahren entdeckt, die weltweit als einzigartig gelten. Während andere also Gutes bieten, bieten wir das Beste.',
      },
      2: {
        title: "Umweltbewusst",
        text: "Ein hervorragendes Produkt ist wertlos, wenn wir damit unsere Umwelt zerstören. Deshalb verwendet unser Unternehmen bei der Produktion nur Rohstoffe und Verpackungsmaterialien, die einen möglichst geringen ökologischen Fußabdruck hinterlassen, und denkt dabei bewusst an die Gesundheit zukünftiger Generationen und eine nachhaltige Zukunft.",
      },
      3: {
        title: "Innovativ",
        text: 'Es kristallisiert sich immer mehr heraus, dass das derzeitige Wissen der Menschheit über die Welt nur an der Oberfläche kratzt. Die Untersuchung tieferer Zusammenhänge, etwa von Synergiebeziehungen, ist schwierig, da diese schwer zu messen sind. Mit beharrlicher Arbeit sind es unsere Ingenieure jetzt." an vorderster Front bei deren Kartierung, wofür sie eine eigene Methode entwickelt haben. Dadurch untersuchen sie die gestellte Aufgabenstellung mit einer besonderen Herangehensweise und arbeiten nicht mit Methoden, die auf trockenen Fakten aus der Literatur basieren.',
      },
    },
  },
  services: {
    title: "Angebotene Leistungen",
    columns: {
      0: {
        title: "Einzigartige Produktentwicklung",
        text: "Wir realisieren Ihr Wunschprodukt, gewürzt mit exzellentem Fachwissen und unter Einhaltung der gesetzlichen Vorgaben. Wir begleiten und setzen Ihre Vision von der Idee bis zur Umsetzung um!\n\nSie können die Abläufe anhand vorbereiteter Muster schnell und genau überprüfen und erhalten jeweils ein individuelles Preisangebot.",
      },
      1: {
        title: "Kosmetik",
        text: "Die meisten Hersteller verwenden für die Herstellung von Kosmetika künstliche Duftstoffe, Farbstoffe und andere synthetisch hergestellte Hilfsstoffe. Andererseits arbeiten wir ausschließlich mit Bio-Zutaten und bevorzugen nur natürliche Zutaten. \n\nWir übernehmen derzeit die Entwicklung von Rezepturen für Kosmetikprodukte, die Vorbereitung von Standardmustern und die Lizenzierung von Kosmetikprodukten unter Berücksichtigung der Kundenbedürfnisse.",
      },
      2: {
        title: "Nahrungsergänzungsmittel",
        text: "Heutzutage wird ein bewusster, gesunder Lebensstil immer beliebter, bei dem Prävention ein fester Bestandteil ist. Der Schwerpunkt verlagert sich zunehmend von der symptomatischen Behandlung hin zur Prävention. Dieser Ansatz gibt Nahrungsergänzungsmitteln ihre Daseinsberechtigung.\n\nWir können sie in den unterschiedlichsten Formen entwickeln und herstellen, sei es als Pulvermischung, Kapsel, Getränkepulver oder flüssiges Nahrungsergänzungsmittel. Wir stellen derzeit viele innovative Produkte her.",
      },
    },
    subtitle: "Kontaktieren Sie uns gerne mit Ihren weiteren Ideen, Konzepten und Fragen!",
    button: "Kontaktiere uns",
  },
  contact: {
    title: "Unsere Kontakte",
    text: "Wenn Sie interessiert sind oder weitere Informationen zu unseren Dienstleistungen, Produkten oder Forschung benötigen, kontaktieren Sie uns bitte unter einer der unten angegebenen Kontaktdaten. Wir freuen uns über Ihre Anfrage! Unser tatkräftiges Team hilft Ihnen gerne weiter!",
    companyName: "Name der Firma",
    email: "E-mail",
    site: "Standort und Sitz",
    phoneNumber: "Telefonnummer",
    page: "Seite",
  },
  tender: {
    title: "Ausschreibung und unsere Richtlinien",
  },
  footer: {
    followUs: "Folgen Sie uns",
    rightsReserved: "Alle Rechte vorbehalten",
  },
};
