<template>
  <main class="home">
    <section class="section" v-for="navItem in navItems" :key="navItem.id" :id="navItem.id">
      <div class="content" :style="bgColor(navItem.backgroundColor)">
        <component
          v-if="visibleSections[navItem.id]"
          :is="getComponentName(navItem.componentName)"
          :title="$t(navItem.label)"
          :backgroundColor="navItem.backgroundColor"
          :backgroundImage="navItem.backgroundImage"
          :id="navItem.id"
        />
      </div>
    </section>
  </main>
</template>

<script lang="ts" setup>
import { PropType, defineProps, ref, onMounted } from "vue";
import { INavItem } from "@/components/organisms/NavBar/utils/INavItem";
import IntroductionSection from "@/components/templates/introduction/IntroductionSection.vue";
import LandingSection from "@/components/templates/landing/LandingSection.vue";
import ServicesSection from "@/components/templates/services/ServicesSection.vue";
import ContactSection from "@/components/templates/contact/ContactSection.vue";
import TenderSection from "@/components/templates/tender/TenderSection.vue";
import FooterSection from "@/components/templates/footer/FooterSection.vue";

const props = defineProps({
  navItems: { type: Array as PropType<INavItem[]>, required: true },
});

const bgColor = (color: string) => {
  return { backgroundColor: color };
};

const components: Record<string, any> = {
  IntroductionSection,
  LandingSection,
  ServicesSection,
  ContactSection,
  TenderSection,
  FooterSection,
};

const getComponentName = (name: string) => {
  return components[name] || null;
};

const visibleSections = ref<Record<string, boolean>>({});

onMounted(() => {
  const observer = new IntersectionObserver(
    (entries) => {
      entries.forEach((entry) => {
        if (entry.isIntersecting) {
          visibleSections.value[entry.target.id] = true;
        }
      });
    },
    {
      threshold: 0.3,
    }
  );

  props.navItems.forEach((navItem) => {
    const element = document.getElementById(navItem.id);
    if (element) {
      observer.observe(element);
    }
  });
});
</script>

<style scoped lang="scss">
.home {
  .section {
    padding-top: 74px;
    margin-top: -74px;
    .content {
      height: 100%;
    }
    &#footer {
      .content {
        height: 100%;
      }
    }
    &#webshop {
      display: none;
    }
  }
}

@media (max-width: 992px) {
  .home {
    .section {
      .content {
        height: 100%;
      }
    }
  }
}
</style>
