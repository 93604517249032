<template>
  <button class="button" :style="getColor(color, background)">{{ buttonLabel }}</button>
</template>

<script setup lang="ts">
import { defineProps } from "vue";
defineProps({
  buttonLabel: String,
  background: String,
  color: String,
});

const getColor = (color: string | undefined, background: string | undefined) => {
  let style: Record<string, string> = {};
  if (background) {
    style.backgroundColor = background;
  }
  if (color) {
    style.color = color;
  }
  return style;
};
</script>

<style scoped lang="scss">
.button {
  padding: 20px 50px;
  border-radius: 100px;
  font-size: 18pt;
  color: $color--black;
  margin-top: 40px;
  font-weight: 500;
  border: none;
  cursor: pointer;
  box-sizing: border-box;
  display: inline-block;
  text-align: center;
  transition: transform 1s ease;
  @media (max-width: 576px) {
    font-size: 12pt;
    padding: 5px 30px;
  }

  &:hover {
    transform: scale(1.1);
  }
}
</style>
